
import {
  defineComponent,
  onMounted,
  Ref,
  ref,
} from "vue";
import Title from "@/components/Title.vue"
import Button from "@/components/Button.vue"
import { useRouter} from "vue-router";
import AuthService from "@/services/AuthService"
import moment from "moment";



export default defineComponent({
  name: "Projects",

  components: {
    Title,
    Button
  },

  setup() {
    const router = useRouter();
    const projects: Ref<any> = ref([]);

    function setLocalStorage(id_project){
      setNameProjects(projects.value, id_project);
      localStorage.setItem('id_project', id_project)
    }

    function sendToListaTickets(id_project){
      setNameProjects(projects.value, id_project);
      setLocalStorage(id_project)
      router.push('listaTickets')
    }

    function sendToFormularios(id_project){
      setLocalStorage(id_project)
      router.push('createFormTickets')
    }

    function setNameProjects(arr, id){
      const newObj = projects.value.filter(proj => proj.id === id).map(proj => proj.name);
      localStorage.setItem('projectsName', JSON.stringify(newObj))
    }

    onMounted(() => {
      projects.value = AuthService.getProjects()
    })

    return {
      history,
      router,
      projects,
      sendToListaTickets,
      sendToFormularios
    };
  },
});
